 .container_title {
    font-size: 50px;
    letter-spacing: 2px;
    line-height: 1.3;
    font-family: 'Roboto Slab', serif;
  }

   .container_subtitle {
    font-style: italic;
    font-weight: 100;
    letter-spacing: 1px;
    line-height: 1.5;
    font-family: "Merriweather", serif;
  }

  .title{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    margin-top: 170px;
  }

  .waviy {
    position: relative;
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: top;
    align-items: center;
  }
  .waviy span {
    position: relative;
    display: inline-block;
    font-size: 40px;
    color: #F9982A;
    text-transform: uppercase;
    animation: flip 4s infinite;
  }
  
  .waviy.lightMode span {
    color: #162F65;
  }



  @keyframes flip {
    0%,80% {
      transform: rotateY(360deg) 
    }
  }






